import TButton from "@/components/guides/button/TButton";
import Chip from "@/components/guides/chip/Chip";
import { colors } from "@/styles/color";
import { boxShadow } from "@/styles/shadow";
import { body12, body14, body24, title24, title38 } from "@/styles/typography";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import EnterTeamSimpleModal from "@/components/modal/EnterTeamSimpleModal";
import PlanModal from "@/components/modal/PlanModal";
import Delete from "@/assets/icons/18px/Delete";
import Check from "@/assets/icons/18px/CheckSvg";
import useTextareaStore from "@/store/useTextareaStore";
import AnalyzedDataCard from "@/components/search/AnalyzedDataCard";
import NewUserModalContent from "@/components/search/NewUserModalContent";
import AutoResizeTextarea from "@/components/guides/textarea/AutoResizeTextarea";
import DimmedModalComponent from "@/components/guides/modal/DimmedModal";
import { useParams } from "react-router-dom";
import {
  getProjectContent,
  patchProjectName,
  postProjectContent,
} from "@/utils/coreService";
import Pagination from "@/components/guides/Pagination";
import DetailModal from "@/components/modal/DetailModal";
import Cookies from "js-cookie";
import SpinnerComponent from "@/components/guides/spinner/Spinner";
import BlockingModalContent, { SERVICE_CODE } from "@/components/modal/BlockingModal";
import DimmedModalWithCloseComponent from "@/components/guides/modal/DimmedModalWithClose";
import ProjectContentDeleteModal from "@/components/modal/ProjectContentDeleteModal";
import { projectContext } from "@/contexts/projectContext";

export default function SearchPage() {
  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    localStorage.setItem("access_token", accessToken);
  }, []);

  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [isEnterTeamModalOpen, setIsEnterTeamModalOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  const openEnterTeamModal = () => {
    setIsEnterTeamModalOpen(() => true);
  };

  const closeEnterTeamModal = () => {
    setIsEnterTeamModalOpen(() => false);
  };

  const openPlanModal = () => {
    setIsPlanModalOpen(() => true);
  };

  const closePlanModal = () => {
    setIsPlanModalOpen(() => false);
  };

  const openDetailModal = () => {
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const [isBlockService, setIsBlockService] = useState(false);

  const [isNewUserPromptBool, setIsNewUserPromptBool] = useState(false);

  useEffect(() => {
    const serviceCode = localStorage.getItem("service-code");

    const hasServiceCode = (serviceCode: string) => {
      return SERVICE_CODE.includes(serviceCode);
    };

    if (hasServiceCode(serviceCode)) {
      setIsBlockService(false);
    } else {
      setIsBlockService(true);
    }
  }, []);

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if (!user.companyId && !isBlockService) {
  //     setIsNewUserPromptBool(true);
  //   } else {
  //     setIsNewUserPromptBool(false);
  //   }
  // }, [isBlockService]);

  const closeDeleteModal = () => {
    setDeleteModal(() => false);
  };

  const closeNewUserPromptModal = () => {
    setIsNewUserPromptBool(() => false);
  };

  const openNewUserPromptModal = () => {
    setIsNewUserPromptBool(() => true);
  };

  const backEnterTeamAction = () => {
    closeEnterTeamModal();
    openNewUserPromptModal();
  };
  const backPlanAction = () => {
    closePlanModal();
    openNewUserPromptModal();
  };

  const [isOpenGuideModal, setIsOpenGuideModal] = useState(true);

  const closeGuideModal = () => {
    setIsOpenGuideModal(() => false);
  };

  const openGuideModal = () => {
    setIsOpenGuideModal(() => true);
  };

  const param = useParams();

  const projectId = String(param.projectId);

  const { getFactualRelation, setFactualRelation } = useTextareaStore();
  const { getKeyIssues, setKeyIssues } = useTextareaStore();

  const factualRelation = getFactualRelation({ projectId });

  const keyIssues = getKeyIssues({ projectId });

  interface RelatedCases {
    caseId: string;
    title: string;
    category: string;
    status: string | null;
    caption: string;
    accuracy: number;
  }
  interface IssueAnalysisData {
    projectId: string;
    contentId: string;
    factualRelation: string;
    keyIssues: string;
    analysisResult: string;
    relatedCases: RelatedCases[];
  }

  const [issueAnalysisData, setIssueAnalysisData] = useState<
    IssueAnalysisData | undefined
  >();

  interface PaginationData {
    page: number;
    take: number;
    totalItem: number;
    totalPage: number;
  }

  const [paginationData, setPaginationData] = useState<PaginationData | undefined>();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState(false);

  const [caseId, setCaseId] = useState("");

  useEffect(() => {
    if (!projectId) {
      return;
    }

    setIsLoading((prev) => !prev);

    const fetchAnalysis = async () => {
      const {
        data: { data, meta },
      } = await getProjectContent({ projectId, page: "1" });

      if (data === null) {
        setIssueAnalysisData(undefined);
        setPaginationData(undefined);
        setIsLoading((prev) => !prev);
        return;
      }

      setIsLoading((prev) => !prev);
      setIssueAnalysisData(data);
      setPaginationData(meta);
      setCurrentPage(meta.page);
    };

    fetchAnalysis();
  }, [projectId]);

  const handleProjectNameChange = useContext(projectContext);

  const handleIssueAnalysisClick = async () => {
    setIsLoading((prev) => !prev);

    const {
      data: { data, meta },
    } = await postProjectContent({
      projectId,
      factualRelation,
      keyIssues,
    });

    if (data.contentId === 1) {
      await patchProjectName({ projectId, newProjectName: data.keyIssues });

      await handleProjectNameChange();
    }

    setIsLoading((prev) => !prev);

    setIssueAnalysisData(data);

    setPaginationData(meta);
    setCurrentPage(meta.page);

    setFactualRelation({ projectId, factualRelation: "" });
    setKeyIssues({ projectId, keyIssues: "" });
  };

  const handleDeleteContentClick = async () => {
    if (!projectId) {
      return;
    }

    setIsLoading((prev) => !prev);

    const {
      data: { data, meta },
    } = await getProjectContent({ projectId, page: "1" });

    if (data === null) {
      setIssueAnalysisData(undefined);
      setPaginationData(undefined);
      setIsLoading((prev) => !prev);
      return;
    }

    setIsLoading((prev) => !prev);
    setIssueAnalysisData(data);
    setPaginationData(meta);
    setCurrentPage(meta.page);
  };

  const handlePaginationButtonClick = async (currentPage) => {
    if (!projectId) {
      return;
    }

    setIsLoading((prev) => !prev);

    const {
      data: { data, meta },
    } = await getProjectContent({ projectId, page: String(currentPage) });

    if (data === null) {
      setIssueAnalysisData(undefined);
      setPaginationData(undefined);
      setIsLoading((prev) => !prev);
      return;
    }

    setIsLoading((prev) => !prev);

    setIssueAnalysisData(data);

    setPaginationData(meta);
  };

  if (!projectId) {
    return;
  }

  if (isBlockService) {
    return (
      <DimmedModalComponent
        isOpened={isBlockService}
        closeModal={() => setIsBlockService(false)}
        eraseCloseIcon={true}
        content={
          <BlockingModalContent handleModal={(isOpen) => setIsBlockService(isOpen)} />
        }
      />
    );
  }

  return (
    <>
      {isLoading && <SpinnerComponent />}

      <StyledSearchPage>
        <DimmedModalComponent
          isOpened={isNewUserPromptBool}
          closeModal={closeNewUserPromptModal}
          eraseCloseIcon={true}
          content={
            <NewUserModalContent
              openEnterTeamModal={openEnterTeamModal}
              closeModal={closeNewUserPromptModal}
              openPlanModal={openPlanModal}
            />
          }
        />

        {deleteModal && (
          <DimmedModalWithCloseComponent
            isOpened={deleteModal}
            closeModal={closeDeleteModal}
            content={
              <ProjectContentDeleteModal
                projectId={projectId}
                contentId={issueAnalysisData?.contentId}
                onDelete={handleDeleteContentClick}
                handleModal={(isOpend) => setDeleteModal(isOpend)}
              />
            }
          />
        )}

        <div className="page-title">세무 분석</div>
        <StyledFactCard>
          <div className="top">
            <Chip
              content={
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: colors.primary_main,
                    fontWeight: "bold",
                  }}
                >
                  1
                </div>
              }
              $bgcolor={colors.primary_sub}
            ></Chip>

            <div className="title-container">
              <div className="title">의뢰 건의 주요 쟁점을 입력하세요.</div>
              <div className="subtitle-container">
                <div className="subtitle">
                  쟁점을 입력하고 쟁점 분석 버튼을 눌러 유사 예규/판례/법령과 이를
                  기반으로 한 쟁점 분석 결과를 확인할 수 있습니다.
                </div>
                <GuideText onClick={openGuideModal}>작성 가이드</GuideText>
                <GuideModal isOpen={isOpenGuideModal}>
                  <span className="close" onClick={closeGuideModal}>
                    &times;
                  </span>
                  <div className="list-item">
                    <Check />
                    <p>법률 용어로 작성</p>
                  </div>
                  <div className="list-item">
                    <Check />
                    <p>정확한 이벤트 발생 시점 포함</p>
                  </div>
                  <div className="list-item">
                    <Check />
                    <p>한 문장으로 작성</p>
                  </div>
                  <div className="list-item">
                    <Check />
                    <p>질의 회신과 유사한 형식</p>
                  </div>
                  <div className="list-item">
                    <Delete />
                    <p>단순 단어 나열</p>
                  </div>
                </GuideModal>
              </div>
            </div>
          </div>

          <AutoResizeTextarea
            value={keyIssues}
            onChange={(e) => setKeyIssues({ projectId, keyIssues: e.target.value })}
            maxLength={800}
            placeholder="1993. 4. 취득한 ○○구 ○○동 소재 아파트 양도 시 1세대 1주택 비과세 적용여부"
            name="issue"
            id="issue"
            rows={1}
          />
          <StyledInputTextContainer>
            <span>
              <span className="key-issues">{keyIssues.length}</span>
              <span> / 800자</span>
            </span>
          </StyledInputTextContainer>

          <div className="top issue-header">
            <Chip
              content={
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: colors.primary_main,
                    fontWeight: "bold",
                  }}
                >
                  2
                </div>
              }
              $bgcolor={colors.primary_sub}
            ></Chip>

            <div>
              <div className="title">의뢰 건의 사실 관계를 입력하세요. (선택사항)</div>
              <div className="subtitle">
                입력하신 사실관계는 더 유사한 예규/판례 검색과 쟁점 분석에 사용되며,
                쟁점만 입력해도 서비스를 이용할 수 있습니다.
              </div>
            </div>
          </div>

          <div className="issue-container">
            <div>
              <StyledIssueCard>
                <div className="content">
                  <AutoResizeTextarea
                    value={factualRelation}
                    onChange={(e) =>
                      setFactualRelation({ projectId, factualRelation: e.target.value })
                    }
                    rows={3}
                    maxLength={500}
                    placeholder="- 1993. 4. ○○구 ○○동 소재 아파트 취득(1세대1주택 비과세 요건 갖춤)
- 1998. 3. 배우자와 자녀 2명 취학(대학교) 관계로 미국 출국
- 2001. 3. ○○구 ○○동 소재 아파트 취득
"
                    name="fact"
                    id="factTA"
                  />
                </div>
              </StyledIssueCard>
            </div>
          </div>
          <StyledAnalsisButtonContainer>
            <div>
              <TButton
                text="쟁점 분석"
                type="gradient"
                onClick={handleIssueAnalysisClick}
                disabled={!keyIssues}
              />
            </div>
          </StyledAnalsisButtonContainer>
        </StyledFactCard>

        {issueAnalysisData && (
          <StyledIssueContainerCard>
            <div className="issue-container">
              <AnalyzedDataCard
                onRelatedCasesClick={(caseId) => {
                  setCaseId(caseId);
                  openDetailModal();
                }}
                projectId={projectId}
                detailId={issueAnalysisData.contentId}
                onDeleteModal={() => setDeleteModal(true)}
                {...issueAnalysisData}
              />
            </div>
            {paginationData && (
              <div className="pagination-container">
                <Pagination
                  onClick={handlePaginationButtonClick}
                  meta={{
                    currentPage,
                    setCurrentPage,
                    page: 5,
                    totalPage: paginationData.totalPage,
                  }}
                />
              </div>
            )}
          </StyledIssueContainerCard>
        )}
      </StyledSearchPage>

      <EnterTeamSimpleModal
        backAction={backEnterTeamAction}
        isOpened={isEnterTeamModalOpen}
        closeModal={closeEnterTeamModal}
        openTeamSimpleModal={() => setIsEnterTeamModalOpen(true)}
      />
      <PlanModal
        backAction={backPlanAction}
        openPlanModal={openPlanModal}
        isOpened={isPlanModalOpen}
        closeModal={closePlanModal}
      />
      {isDetailModalOpen && (
        <DetailModal
          caseId={caseId}
          backAction={closeDetailModal}
          isOpened={isDetailModalOpen}
          closeModal={closeDetailModal}
        />
      )}
    </>
  );
}

const StyledSearchPage = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-color: ${colors.bg_con};
  min-height: 100vh;
  padding: 50px 0 20px 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .page-title {
    font-size: ${title38};
    font-weight: bold;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
`;
const StyledFactCard = styled.div`
  width: 900px;
  border-radius: 12px;
  background-color: white;
  ${boxShadow}
  padding: 30px;
  margin-right: 70px;

  .title-continer {
    width: 100%;
  }

  .title {
    font-size: ${title24};
    font-weight: 500;
    margin-bottom: 8px;
  }
  .subtitle {
    font-size: ${body14};
    color: ${colors.neutral_g4};
  }

  .subtitle-container {
    position: relative;
    display: flex;
    width: 795px;
    justify-content: space-between;

    align-items: center;
  }

  .top {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
    margin-bottom: 20px;
  }

  .issue-header {
    margin-top: 8px;
  }

  .issue-container {
    width: 100%;
  }
`;

const StyledIssueCard = styled.div`
  background-color: white;
  border-radius: 12px;

  .content {
    display: flex;
    align-items: end;
    gap: 10px;
  }
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-size: ${body24};
      margin: 0;
    }
  }
`;

// const StyledModalContent = styled.div`
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
//   padding: 5px;

//   .modal-title {
//     font-weight: bold;
//     font-size: 20px;
//   }

//   .modal-content {
//     font-size: 18px;
//     font-weight: 500;
//     white-space: nowrap;
//     color: ${colors.primary_main};
//     background-color: ${colors.primary_sub};
//     border-radius: 12px;
//     padding: 56px 56px;
//     max-width: 100%;
//     margin: 0 auto;
//   }

//   .button-container {
//     display: flex;
//     gap: 20px;
//     justify-content: center;
//   }
// `;

const GuideText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.primary_main};
  cursor: pointer;

  border-bottom: 1px solid ${colors.primary_main};
`;

const GuideModal = styled.article<{ isOpen: boolean }>`
  position: absolute;
  top: 45px;
  right: -70px;
  border-radius: 10px;
  padding: 20px 15px;
  width: 230px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 8px;

  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  .list-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .list-x {
    height: 19px;
    font-size: 29px;
    color: ${colors.primary_main};
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.primary_main};
  }
  .close {
    position: absolute;
    top: 8px;
    right: 15px;
    color: ${colors.neutral_g3};
    font-size: 29px;
    cursor: pointer;
  }
`;

const StyledInputTextContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;

  margin-top: 8px;

  span {
    width: fit-content;

    color: ${colors.neutral_g3};

    ${body12}

    .key-issues {
      color: ${colors.primary_main};
    }
  }
`;

const StyledAnalsisButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;

  margin-top: 16px;

  div {
    width: 172px;
  }
`;

const StyledIssueContainerCard = styled.div`
  width: 900px;
  border-radius: 12px;
  background-color: white;
  ${boxShadow}
  padding: 30px;

  .title {
    font-size: ${title24};
    font-weight: 500;
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: ${body14};
    color: ${colors.neutral_g4};
  }

  .top {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
    margin-bottom: 10px;
  }

  .issue-container {
    padding: 15px;
    background-color: ${colors.bg_con};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
  }

  .pagination-container {
    margin-bottom: 5px;
  }
`;
