import TButton from "@/components/guides/button/TButton";
import { body16 } from "@/styles/typography";
import styled from "styled-components";
import iconLogo from "@/assets/logo/iconLogo.svg";
import textLogo from "@/assets/logo/textLogo.svg";
import serviceInfoIcon from "@/assets/icons/20px/service_info.svg";
import logoutIcon from "@/assets/icons/20px/logout.svg";
import DropupComponent from "@/components/guides/dropup/dropup";
import ListItem from "../components/layouts/consoleLayout/ListItem";
import { boxShadow3 } from "@/styles/shadow";
import { useEffect, useRef, useState } from "react";
import MenuIcon from "../assets/icons/24px/menu.svg";
import { getProjects, postProject } from "@/utils/coreService";
import { colors } from "@/styles/color";
import { useNavigate, useParams } from "react-router-dom";
import { projectContext } from "@/contexts/projectContext";
import useTextareaStore from "@/store/useTextareaStore";

export default function ConsoleLayout({ children }) {
  const {
    nickname: userName,
    id: userId,
    companyPlan,
  } = JSON.parse(localStorage.getItem("user"));

  const [isSidebarOpen, setIsSideBarOpen] = useState(true);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { createProject, project } = useTextareaStore();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1200px)");
    const documentWidthChange = (e) => {
      setIsSideBarOpen(e.matches);
    };

    mediaQuery.addEventListener("change", documentWidthChange);
    documentWidthChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", documentWidthChange);
    };
  }, []);

  const snackBarRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (window.innerWidth < 1200) {
      if (snackBarRef.current && snackBarRef.current.contains(event.target as Node)) {
        setIsSideBarOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      const result = await getProjects({ userId });

      if (result.data.length === 0) {
        return;
      }

      result.data.forEach((projectData) => {
        if (!project[projectData.id]) {
          createProject({ projectId: projectData.id });
        }
      });

      setProjects(result.data);

      if (!projectId) {
        navigate(`/project/${result.data[0].id}`);
      }
    };

    fetchProject();
  }, [createProject, navigate, project, projectId, userId]);

  const toggleSideBar = () => {
    setIsSideBarOpen((prev) => !prev);
  };

  const handleNewProjectButtonClick = async () => {
    const result = await postProject({ userId });

    createProject({ projectId: result.data.result.id });

    setProjects((prev) => [...prev, result.data.result]);

    navigate(`/project/${result.data.result.id}`);
  };

  const handleProjectNameChange = async () => {
    const result = await getProjects({ userId });

    setProjects(result.data);
  };

  return (
    <StyledConsoleLayout $isSidebarOpen={isSidebarOpen}>
      <div className={`side-bar ${isSidebarOpen ? "open" : ""}`}>
        <div className="top">
          <div className="head">
            <img src={iconLogo} alt="icon logo image" />
            <img src={textLogo} alt="text logo image" />
          </div>

          <div className="btn-container">
            <TButton onClick={handleNewProjectButtonClick} text="새 프로젝트 만들기" />
          </div>
          <div className="list">
            {projects.map((project) => {
              return (
                <ListItem
                  id={project.id}
                  text={project.name}
                  isShared={project.isShared}
                  onChangeName={handleProjectNameChange}
                  projectLength={projects.length}
                />
              );
            })}
          </div>
        </div>

        <div className="nav">
          <DropupComponent
            options={[
              {
                label: "서비스 이용안내",
                icon: serviceInfoIcon,
                link: "https://taxcanvas.notion.site/7ce097f660f342d1b8c87c8ceb88e5ff?pvs=74",
              },
              // {
              //   label: "고객센터",
              //   icon: csIcon,
              //   link: "/service",
              // },
              // {
              //   label: "설정",
              //   icon: settingsIcon,
              //   link: "/settings/profile",
              // },
              {
                label: "로그아웃",
                icon: logoutIcon,
                link: "/logout",
              },
            ]}
            placeholder={
              <>
                <div>{userName}</div>
                <div style={{ color: colors.secondary_main }}>{companyPlan}</div>
              </>
            }
          />
        </div>
      </div>

      <projectContext.Provider value={handleProjectNameChange}>
        <div className="content" ref={snackBarRef}>
          {children}
        </div>
      </projectContext.Provider>

      <img src={MenuIcon} className="menu-icon" onClick={toggleSideBar} />
    </StyledConsoleLayout>
  );
}

const StyledConsoleLayout = styled.div<{ $isSidebarOpen: boolean }>`
  display: flex;
  position: relative;
  .side-bar {
    z-index: 9;
    width: 294px;
    min-width: 294px;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    padding: 40px 34px 0 34px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.3s ease;
    transform: translateX(${(props) => (props.$isSidebarOpen ? "0" : "-100%")});
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      img:nth-child(2) {
        width: 158px;
      }
    }
    .btn-container {
      padding: 30px 5px 22px 5px;
      font-size: ${body16};
      button {
        ${boxShadow3};
        opacity: 0.6;
      }
      button:hover {
        opacity: 1;
      }
    }
    .list {
      overflow-y: scroll;
      max-height: calc(100vh - 300px);
      margin-bottom: 16px;
      /* &:hover {   
                &::-webkit-scrollbar {
                    display: block;
                }
            } */

      &::-webkit-scrollbar {
        display: none;
      }
    }
    a {
      text-decoration: none;
    }
    .nav {
      margin: 15px -18px 16px -18px;
    }
  }
  .content {
    flex-grow: 1;
  }

  .menu-icon {
    display: none;
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    .menu-icon {
      display: block;
      z-index: 200;
    }

    .side-bar {
      position: fixed;
      z-index: 100;
      .head {
        display: none;
      }
      .btn-container {
        padding-top: 41px;
      }
    }
  }
`;
